import {setFormFieldVisuallyDirty} from "./setFormFieldVisuallyDirty.js";
import {getWindowFromWindowId} from "../../window/getWindowFromWindowId.js";
import {cellIsDirty} from "../cellIsDirty.js";

export const updateValueInWindowFormObject = ({value, name, windowId}) => {
  const window = getWindowFromWindowId({windowId, session: global.session});
  const formRowWindow = window.sub?.window ?? window;
  if ((!value || value === "") && value !== false) value = null;

  const row = formRowWindow.output?.Table?.Rows[0];
  const originalRow = formRowWindow.originalFormRow;

  if (!row) return;
  const cell = row.find((cell) => cell?.Column?.Name === name);
  const originalCell = originalRow.find((cell) => cell?.Column?.Name === name);

  if (!cell) return;
  cell.NewValue = value;

  const originalValue = originalCell?.Value;

  cell.IsDirty = cellIsDirty({cell, newValue: value, originalValue});

  setFormFieldVisuallyDirty({fieldName: name, window, dirty: cell.IsDirty});
};
